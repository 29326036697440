import React, { useState } from 'react'
import { Table, TableBody, TableRow, TableCell, Typography, Checkbox } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  Search,
  Paper,
  PaperContent,
  PaperToolbar,
  TablePagination,
  TableHead,
  LoadingTableRow,
} from 'components'
import { useTranslation } from 'react-i18next'
import matchSorter from 'match-sorter'
import { stableSort, getSorting } from 'utils/tableSorting'
import DownloadCsv from '../DownloadCsv'
import SortingChips from '../SortingChips'
import AdGroupFilter from '../AdGroupFilter'
import SingleUserTableRow from '../SingleUserTableRow'
import SelectedToolbar from '../SelectedToolbar'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: theme.spacing(3),
  },
  tableContainer: {
    overflow: 'auto',
  },
  chipsContainer: {
    marginTop: '0px',
    paddingTop: '0px',
  },
  checkbox: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}))

const UsersTable = ({ storedUsers, routeIsUsers, customerId, loading, history, location }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedUsers, setSelectedUsers] = useState([])
  const [activeFilters, setActiveFilters] = useState({})

  const headCells = [
    {
      id: 'name',
      label: t('Users_user'),
      numeric: false,
      disablePadding: false,
      paddingLeft: false,
      sortable: true,
    },
    {
      id: 'mobileNumber',
      label: t('Dashboard_invoice_mobile'),
      numeric: false,
      disablePadding: true,
      paddingLeft: false,
      sortable: true,
    },
    {
      id: 'landlineNumber',
      label: t('Drawer_cancel_teams_title'),
      numeric: false,
      disablePadding: true,
      paddingLeft: false,
      sortable: true,
    },
    {
      id: 'monthlyPrice',
      label: t('Users_price'),
      numeric: true,
      disablePadding: true,
      paddingLeft: true,
      sortable: true,
    },
  ]

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const searchedUsers = matchSorter(storedUsers, searchTerm, {
    keys: [
      'name',
      'email',
      'abbType',
      'mobileNumber',
      'teamsDisplayNumber',
      'simCards',
      'azureId',
      'userId',
      'mobileOneNumberShadowNumber',
    ],
  })

  const filteredUsers = searchedUsers.filter((user) =>
    Object.keys(activeFilters).every((key) => user[key] === activeFilters[key])
  )

  const handleSelectAllUsers = () => {
    if (selectedUsers.length > 0 && selectedUsers.length > filteredUsers.length) {
      setSelectedUsers([])
    } else if (selectedUsers.length === filteredUsers.length) {
      setSelectedUsers([])
    } else if (selectedUsers.length === 0) {
      setSelectedUsers(filteredUsers)
    } else {
      setSelectedUsers([])
    }
  }
  const handleSelectSingleUser = (user) => {
    const userExists = selectedUsers.find((x) => x.certainId === user.certainId)
    if (userExists) {
      let newSelected = []
      const idx = selectedUsers.findIndex((x) => x.certainId === user.certainId)
      if (idx === 0) {
        newSelected = newSelected.concat(selectedUsers.slice(1))
      } else if (idx === selectedUsers.length - 1) {
        newSelected = newSelected.concat(selectedUsers.slice(0, -1))
      } else if (idx > 0) {
        newSelected = newSelected.concat(selectedUsers.slice(0, idx), selectedUsers.slice(idx + 1))
      }
      setSelectedUsers(newSelected)
    } else {
      setSelectedUsers(selectedUsers.concat(user))
    }
  }

  const handleSetSearchTerm = (search) => {
    setPage(0)
    setSearchTerm(search)
  }

  const handleSetRowsPerPage = (newRowsPerPage) => {
    setPage(0)
    setRowsPerPage(newRowsPerPage)
  }

  return (
    <>
      <Paper>
        <PaperToolbar className={classes.toolbar}>
          <Search
            filter={handleSetSearchTerm}
            placeholder={t('Users_search')}
            autoFocus={routeIsUsers}
            disabled={storedUsers.length === 0}
          />
          <AdGroupFilter customerId={customerId} />
          <DownloadCsv customerId={customerId} />
        </PaperToolbar>
        <PaperContent className={classes.chipsContainer}>
          <SortingChips
            setPage={setPage}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            filteredUsers={filteredUsers}
          />
        </PaperContent>
      </Paper>

      <div style={{ overflow: 'auto', marginTop: 24, width: '100%' }}>
        <Table aria-labelledby="Users table" size="small">
          {selectedUsers.length > 0 ? (
            <SelectedToolbar
              selectedUsers={selectedUsers}
              totalCount={storedUsers.length}
              customerId={customerId}
              utilityIcon={
                <Checkbox
                  color="primary"
                  className={classes.checkbox}
                  checked={storedUsers.length > 0 && selectedUsers.length === storedUsers.length}
                  disabled={!filteredUsers || filteredUsers.length === 0}
                  indeterminate={
                    selectedUsers.length > 0 && selectedUsers.length < storedUsers.length
                  }
                  onChange={handleSelectAllUsers}
                  inputProps={{
                    'aria-label': 'Select all users to perform bulk actions',
                  }}
                />
              }
            />
          ) : (
            <TableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              utilityIcon={
                <Checkbox
                  color="primary"
                  className={classes.checkbox}
                  checked={storedUsers.length > 0 && selectedUsers.length === storedUsers.length}
                  disabled={!filteredUsers || filteredUsers.length === 0}
                  indeterminate={
                    selectedUsers.length > 0 && selectedUsers.length < storedUsers.length
                  }
                  onChange={handleSelectAllUsers}
                  inputProps={{
                    'aria-label': 'Select all users to perform bulk actions',
                  }}
                />
              }
            />
          )}

          <TableBody>
            {filteredUsers.length > 0 &&
              stableSort(filteredUsers, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => {
                  const isUserSelected = selectedUsers.find((sUser) => {
                    return sUser.certainId === user.certainId
                  })
                  return (
                    <SingleUserTableRow
                      isMobile={isMobile}
                      key={user.certainId}
                      user={user}
                      handleSelectSingleUser={handleSelectSingleUser}
                      isUserSelected={Boolean(isUserSelected)}
                      history={history}
                      location={location}
                    />
                  )
                })}
            {!loading && filteredUsers.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography align="center">
                    {t('Organization_unassignedsimcards_noresult')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {loading && <LoadingTableRow />}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPage={rowsPerPage}
        page={page}
        totalCount={filteredUsers.length}
        setPage={setPage}
        setRowsPerPage={handleSetRowsPerPage}
      />
    </>
  )
}

export default UsersTable
