import React, { useEffect, useState } from 'react'
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchInternationalNumbersV2 } from 'state/internationalNumbersV2/actions'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useTranslation } from 'react-i18next'
import { Paper, PaperContent, Alert } from '@skytdc/mui/components'
import { AppState } from 'state/reducers'
import { phoneNumberFormat } from 'utils/helpers'
import NumbersTable from './components/NumbersTable'
import SingleNumberDrawer from '../SingleNumberDrawer'
import ProvisionDialog from '../ProvisionDialog'

interface Props extends RouteComponentProps {
  customerId: string
  history: any
}

const AssignedNumbers = ({ customerId, history }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loading, data, pageNumber, pageSize, error } = useSelector(
    (state: AppState) => state.internationalNumbersV2
  )
  const { totalResultSize } = data?.pageing || {}
  const progress = Math.ceil((data?.data?.length * 100) / totalResultSize) || 0
  const [fetchingError, setFetchingError] = useState(false)

  useEffect(() => {
    changeDocumentTitle(t('Addons_int_numbers_title'), ' - ', ':applicationName')
  }, [])

  useEffect(() => {
    if (
      (!loading && !pageNumber && !error) ||
      (!loading &&
        !error &&
        data?.data.length < totalResultSize &&
        pageNumber <= Math.ceil(totalResultSize / pageSize) - 1)
    ) {
      dispatch(fetchInternationalNumbersV2({ customerId, pageNumber, pageSize }))
    }
  }, [!loading])

  if (!fetchingError && error) {
    setFetchingError(true)
  }

  const handleOpenNumberDrawer = (number: string) => {
    history.push(`/${customerId}/internationale-numre/tildelte-numre/${number}`)
  }

  const mappedNumbers = data
    ? data.data.map((x) => {
        const isMediationMissing = Boolean(x.productVersion === 2 && !x.mediation)
        const hasValidReference = Boolean(x.references && x.references.length === 1)
        const customerIdsMatch =
          customerId === x.subscription?.customerId &&
          customerId === x.references[0]?.ani?.customerId &&
          customerId === x.references[0]?.referencedSubscriptions[0]?.customerId
        const hasConsistentCustomerIds =
          (customerIdsMatch && x.productVersion === 1) ||
          (customerIdsMatch &&
            x.productVersion === 2 &&
            x.mediation &&
            x.mediation.customerId === customerId)
        return {
          ...x,
          numberWithCountryCode: `+${x.country.prefix} ${phoneNumberFormat(x.inumber)}`,
          countryName: x.country.name,
          comments: x.subscription.extraInfo || '',
          // the object 'mediation' is missing for v1. If it is also missing for a v2 number, then flag the number with an error
          isMediationMissing,
          // if more than 1, check if endDates overlap TODO
          hasValidReference,
          // 'customerId' is shown three places: subscription.customerId, references.ani.customerId, references.referencedSubscriptions.customerId, and mediation.customerId. If they don't match then flag the number with an error
          hasInconsistentCustomerIds: !hasConsistentCustomerIds,
          hasErrors: isMediationMissing || !hasValidReference || !hasConsistentCustomerIds,
        }
      })
    : []

  if (fetchingError) {
    return (
      <Paper style={{ maxWidth: 600 }}>
        <PaperContent>
          <Alert type="error" message={t('Dashboard_stock_some_error')} />
        </PaperContent>
      </Paper>
    )
  }
  return (
    <>
      <NumbersTable
        numbers={mappedNumbers}
        progress={progress}
        loading={!data?.data}
        handleOpenNumberDrawer={handleOpenNumberDrawer}
      />
      <Switch>
        {mappedNumbers.map((x, i) => {
          return (
            <Route
              key={x}
              render={(props) => (
                <SingleNumberDrawer {...props} number={x} customerId={customerId} />
              )}
              exact
              path={`/${customerId}/internationale-numre/tildelte-numre/${x.inumber}`}
            />
          )
        })}
        <Route
          render={(props) => (
            <ProvisionDialog
              {...props}
              redirectUrl={`/${customerId}/internationale-numre/tildelte-numre`}
              customerId={customerId}
            />
          )}
          exact
          path={`/${customerId}/internationale-numre/tildelte-numre/opret`}
        />
        <Redirect to={`/${customerId}/internationale-numre/tildelte-numre`} />
      </Switch>
    </>
  )
}

export default AssignedNumbers
