import React, { useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import { useDispatch, useSelector, shallowEqual, batch } from 'react-redux'
import { fetchAuthorizations } from 'state/authorizations/actions'
import { fetchAzureAppSecrets } from 'state/azureAppSecrets/actions'
import { useTranslation } from 'react-i18next'
import { fetchTeamsUsers } from 'state/teamsUsers/actions'
import { fetchAvailableRoles } from 'state/availableRoles/actions'
import { updateUser, deleteUser } from 'services/sweb/user'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, List } from '@material-ui/core'
import {
  PaperLoader,
  Search,
  PaperFeedbackMessage,
  Paper,
  PaperToolbar,
  PaperContent,
  EnhancedTablePagination,
} from 'components'
import AdGroupFilter from 'views/Users/components/AdGroupFilter'
import UserListItemWithRole from './UserListItemWithRole'
import RoleChip from './RoleChip'

const useStyles = makeStyles((theme) => ({
  intro: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  title: {
    fontWeight: 700,
  },
  chipsContainer: {
    marginTop: theme.spacing(3),
    padding: 0,
    paddingLeft: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  userContainer: {
    maxHeight: '450px',
    overflow: 'auto',
    marginBottom: theme.spacing(3),
  },
  roleContainer: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    paddingTop: 0,
  },
}))

const searchOptions = {
  shouldSort: true,
  threshold: 0.1,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: [
    'id',
    'mail',
    'givenName',
    'surname',
    'userPrincipalName',
    'mobilePhone',
    'jobTitle',
    'displayName',
  ],
}

const Roles = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [roleLoading, setRoleLoading] = useState(false)
  const [currentRoles, setCurrentRoles] = useState(null)
  const [showActive, setShowActive] = useState(false)

  const roleIds = (currentRoles && currentRoles.map((x) => x.azure_id)) || []
  const usersWithRoles = users && users.filter((x) => roleIds.indexOf(x.id) !== -1)

  const stored = useSelector(
    (state) => ({
      azureAppSecrets: state.azureAppSecrets,
      authorizations: state.authorizations,
      availableRoles: state.availableRoles,
      teamsUsers: state.teamsUsers,
    }),
    shallowEqual
  )

  const azureTenantId = stored.azureAppSecrets.data?.AppSecretOutput?.tenantId

  const filterUsers = (searchTerm) => {
    if (page > 0) setPage(0)
    const fuse = new Fuse(users, searchOptions)
    const result = fuse.search(searchTerm)
    setFilteredUsers(
      searchTerm.length > 0 ? result : (showActive && usersWithRoles) || stored.teamsUsers.data
    )
  }

  useEffect(() => {
    if (showActive && usersWithRoles && usersWithRoles.length > 0) {
      setUsers(usersWithRoles)
      setFilteredUsers(usersWithRoles)
    } else if (users.length > 0) {
      setUsers(stored.teamsUsers.data)
      setFilteredUsers(stored.teamsUsers.data)
    }
  }, [showActive])

  const addRoleToUser = (azureId, roleName) => {
    setRoleLoading(true)
    updateUser
      .role({ customerId: match.params.customer_id, azureTenantId, azureId, roleName })
      .then(() => {
        enqueueSnackbar(t('Organization_roles_success'), { variant: 'success' })
        setRoleLoading(false)
        dispatch(fetchAuthorizations({ customerId: match.params.customer_id }))
      })
      .catch((err) => {
        err.response.json().then((res) =>
          enqueueSnackbar(t('Organization_roles_error', { error: res.error.text }), {
            variant: 'error',
          })
        )
        setRoleLoading(false)
      })
  }

  const removeRoleFromUser = (azureId, roleName) => {
    setRoleLoading(true)
    deleteUser
      .role({ customerId: match.params.customer_id, azureTenantId, azureId, roleName })
      .then(() => {
        enqueueSnackbar(t('Organization_roles_removed'), { variant: 'success' })
        setRoleLoading(false)
        dispatch(fetchAuthorizations({ customerId: match.params.customer_id }))
      })
      .catch((err) => {
        err.response.json().then((res) =>
          enqueueSnackbar(t('Organization_roles_remove_error', { error: res.error.text }), {
            variant: 'error',
          })
        )
        setRoleLoading(false)
      })
  }

  const toggleActive = () => setShowActive(!showActive)

  useEffect(() => {
    batch(() => {
      dispatch(fetchTeamsUsers({ customerId: match.params.customer_id }))
      dispatch(fetchAzureAppSecrets({ customerId: match.params.customer_id }))
      dispatch(fetchAuthorizations({ customerId: match.params.customer_id }))
      dispatch(fetchAvailableRoles())
    })
  }, [])

  useEffect(() => {
    if (stored.teamsUsers.data) {
      setUsers(stored.teamsUsers.data)
      setFilteredUsers(stored.teamsUsers.data)
    }
  }, [stored.teamsUsers])

  useEffect(() => {
    if (
      !stored.authorizations.loading &&
      stored.authorizations.data &&
      stored.authorizations.data.length > 0 &&
      azureTenantId
    ) {
      const getCustomer = stored.authorizations.data.find(
        (auth) => auth.customer.customer_id === Number(match.params.customer_id)
      )
      const getUsersFromTenant =
        azureTenantId &&
        getCustomer &&
        getCustomer.user_authorizations.filter((user) => user.azure_tenant_id === azureTenantId)
      setCurrentRoles(getUsersFromTenant)
    } else {
      setCurrentRoles([])
    }
  }, [stored.authorizations, stored.azureAppSecrets])

  let content = <PaperLoader />

  if (!stored.teamsUsers.loading && stored.teamsUsers.error) {
    content = (
      <PaperFeedbackMessage
        type="error"
        withMargin
        disableGutters={false}
        message={t('Organization_roles_users_error', {
          error: stored.teamsUsers.error.statusText,
        })}
      />
    )
  }

  if (!stored.azureAppSecrets.loading && stored.azureAppSecrets.error) {
    content = (
      <PaperFeedbackMessage
        type="error"
        withMargin
        disableGutters={false}
        message={t('Organization_roles_app_error')}
      />
    )
  }

  if (!stored.authorizations.loading && stored.authorizations.error) {
    content = (
      <PaperFeedbackMessage
        type="error"
        withMargin
        disableGutters={false}
        message={t('Organization_roles_role_error', {
          error: stored.authorizations.error.statusText,
        })}
      />
    )
  }

  if (!stored.availableRoles.loading && stored.availableRoles.error) {
    content = (
      <PaperFeedbackMessage
        type="error"
        withMargin
        disableGutters={false}
        message={t('Organization_roles_avail_roles_error', {
          error: stored.availableRoles.error.statusText,
        })}
      />
    )
  }

  if (!stored.teamsUsers.loading && stored.teamsUsers.data && stored.teamsUsers.data.length === 0) {
    content = (
      <PaperFeedbackMessage
        message={t('Organization_roles_users_amount', { amount: 0 })}
        withMargin
        disableGutters={false}
      />
    )
  }

  if (
    users.length > 0 &&
    stored.availableRoles.data &&
    stored.authorizations.data &&
    stored.teamsUsers.customerId === match.params.customer_id
  ) {
    content = (
      <>
        {currentRoles && currentRoles.length >= 0 ? (
          <div className={classes.roleContainer}>
            <Typography variant="h5" className={classes.title} gutterBottom>
              {t('Organization_roles_users_amount', {
                amount: (stored.teamsUsers.data && stored.teamsUsers.data.length) || 0,
              })}
            </Typography>
            <PaperToolbar className={classes.toolbar}>
              <Search placeholder={t('Organization_roles_user_search')} filter={filterUsers} />
              <AdGroupFilter customerId={match.params.customer_id} combinedUsers={false} />
            </PaperToolbar>
            {usersWithRoles.length > 0 && (
              <PaperContent className={classes.chipsContainer}>
                <RoleChip
                  amount={usersWithRoles.length}
                  isActive={showActive}
                  setActive={toggleActive}
                />
              </PaperContent>
            )}
            <List className={classes.userContainer}>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => {
                  return (
                    <UserListItemWithRole
                      name={user.displayName || 'No name'}
                      email={user.mail}
                      key={user.id}
                      azureId={user.id}
                      availableRoles={stored.availableRoles.data}
                      customerId={match.params.customer_id}
                      currentRoles={currentRoles.filter((auth) => {
                        return auth.azure_id === user.id
                      })}
                      loading={roleLoading || stored.authorizations.loading}
                      addRole={addRoleToUser}
                      removeRole={removeRoleFromUser}
                    />
                  )
                })}
            </List>
            {users.length > 9 && (
              <EnhancedTablePagination
                arrayLength={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                entriesPerPageText={t('Users_pagination')}
              />
            )}
          </div>
        ) : (
          <PaperFeedbackMessage message={t('Organization_roles_need_role')} withMargin />
        )}
      </>
    )
  }

  return (
    <Paper>
      <Typography variant="body1" className={classes.intro}>
        {t('Organization_roles_description')}
      </Typography>
      {content}
    </Paper>
  )
}

export default Roles
