import React, { useEffect, useState } from 'react'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useSelector, useDispatch, batch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { updateSubscription, getSubscription } from 'services/sweb/subscription'
import { fetchSimcards } from 'state/simcards/actions'
import { fetchSimcardOrders } from 'state/simcardOrders/actions'
import { Slide } from '@material-ui/core'
import { PaperLoader, CustomDialog, PaperFeedbackMessage } from 'components'
import { useTranslation } from 'react-i18next'
import Simcard from './Simcard'
import ActiveSimcardOrder from '../components/ActiveSimcardOrder'

const Simcards = ({ subscription, customerId, changeRoute, title }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    title: null,
    message: null,
    callback: null,
  })
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const simcards = useSelector((state) => state.simcards)
  const simcardOrders = useSelector((state) => state.simcardOrders)

  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
    batch(() => {
      dispatch(
        fetchSimcards({
          customerId,
          mobileNumber: subscription.mobile_number,
        })
      )
      dispatch(
        fetchSimcardOrders({
          customerId,
          mobileNumber: subscription.mobile_number,
        })
      )
    })
  }, [])

  const goToOrderForm = () => changeRoute('bestil-simkort')

  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    })
  }

  const blockSimcard = () => {
    handleCloseDialog()
    setLoading(true)
    updateSubscription
      .blockSimcard({
        customerId,
        phoneNumber: subscription.mobile_number,
      })
      .then(() => {
        dispatch(
          fetchSimcards({
            customerId,
            mobileNumber: subscription.mobile_number,
          })
        )
        enqueueSnackbar(t('Drawer_simcard_block_success'), { variant: 'success' })
        setLoading(false)
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  const handleOpenDialog = () => {
    setDialog({
      open: true,
      title: t('Drawer_simcard_block_prompt'),
      message: t('Drawer_simcard_block_desc_two'),
      callback: blockSimcard,
    })
  }

  /* const unblockSimcard = () => {
    setLoading(true)
    updateSubscription
      .unblockSimcard({ customerId, phoneNumber: subscription.mobile_number })
      .then(() => {
        enqueueSnackbar(t('Drawer_simcard_unblocked'), { variant: 'success' })
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  } */

  const simcardOrdersResolved = Boolean(
    simcardOrders.error === undefined || simcardOrders.data || simcardOrders.data === undefined
  )

  if (simcards.error) {
    return <PaperFeedbackMessage withMargin message={simcards.error.statusText} type="error" />
  }

  if (simcards.data && simcardOrdersResolved) {
    return (
      <Slide in direction="left">
        <div>
          {simcardOrders.data && simcardOrders.data.new_simcard && (
            <ActiveSimcardOrder order={simcardOrders.data} />
          )}
          <Simcard
            simcard={simcards.data.primary}
            title={t('Drawer_simcard_simcard')}
            withButtons
            goToOrderForm={goToOrderForm}
            mobileNumber={subscription.mobile_number}
            handleOpenDialog={handleOpenDialog}
            esimActivationCode={simcards.data?.primary?.esimActivationCode}
            newEsimOrder={{
              activationCode: simcardOrders.data?.activation_code,
              identifier: simcardOrders.data?.identifier,
              loading: simcardOrders.loading,
            }}
            // unblockSimcard={unblockSimcard}
            hideOrderButton={Boolean(simcardOrders.data && simcardOrders.data.new_simcard)}
            loading={loading}
            changeRoute={changeRoute}
          />
          {simcards.data.the_rest &&
            simcards.data.the_rest.length > 0 &&
            simcards.data.the_rest.map((simcard, index) => {
              return (
                <Simcard
                  key={simcard.id}
                  esimActivationCode={simcards.data?.primary?.esimActivationCode}
                  simcard={simcard}
                  title={
                    simcards.data.the_rest.length > 1
                      ? t('Drawer_simcard_datashare_number', { number: index + 1 })
                      : t('Drawer_simcard_datashare')
                  }
                />
              )
            })}
          <CustomDialog
            isOpen={dialog.open}
            confirmButtonText={t('Drawer_simcard_block_labl')}
            onConfirm={dialog.callback}
            onCancel={handleCloseDialog}
            title={dialog.title}
            contentText={dialog.message}
          />
        </div>
      </Slide>
    )
  }
  return <PaperLoader />
}

export default Simcards
