import React, { Fragment, useState, useEffect } from 'react'
import { updateCustomer, deleteCustomer } from 'services/sweb/customer'
import Fuse from 'fuse.js'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItem,
  Switch,
  Divider,
  Link,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import {
  PaperLoader,
  PaperFeedbackMessage,
  CustomDialog,
  Search,
  EnhancedTablePagination,
  PaperToolbar,
} from 'components'
import Alert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'
import { FolderSharedOutlined as GroupIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  introText: {
    lineHeight: '18px',
    marginBottom: theme.spacing(),
  },
  alertRoot: {
    marginTop: theme.spacing(2),
  },
  alert: {
    lineHeight: '18px',
  },
  alertButton: {
    color: theme.palette.error.main,
  },
}))

const searchOptions = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ['displayName', 'id'],
}

const Dialog = ({
  adGroupFilters,
  availableAdGroups,
  handleClose,
  isOpen,
  customerId,
  users,
  fetchUsers,
  getUnAvailableFilterGroups,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [hasChanged, setHasChanged] = useState(false)
  const [closing, setClosing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState([])
  const [unAvailableFilterGroups, setUnAvailableFilterGroups] = useState(getUnAvailableFilterGroups)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filteredAdGroups, setFilteredAdGroups] = useState([])
  const [showActive, setShowActive] = useState(false)

  const activeGroups =
    (availableAdGroups.data &&
      availableAdGroups.data.length > 0 &&
      availableAdGroups.data.filter((x) => filters.indexOf(x.id) !== -1)) ||
    []

  useEffect(() => {
    if (adGroupFilters.data && !closing && !hasChanged) {
      setFilters(adGroupFilters.data.map((x) => x.id))
    }
  }, [adGroupFilters])

  useEffect(() => {
    if (showActive) {
      setFilteredAdGroups(activeGroups)
    } else {
      setFilteredAdGroups(availableAdGroups.data || [])
    }
    setPage(0)
  }, [showActive])

  useEffect(() => {
    if (availableAdGroups.data) {
      setFilteredAdGroups(availableAdGroups.data)
    }
  }, [availableAdGroups])

  useEffect(() => {
    if (!users.loading && users.data && closing) {
      handleClose()
    }
  }, [users])

  const closeDialog = () => {
    if (hasChanged) {
      setLoading(true)
      setClosing(true)
      fetchUsers()
    } else {
      handleClose()
    }
  }

  const handleSetFilterGroup = (checked, id) => {
    setLoading(true)

    const newFilters = checked ? [...filters, id] : filters.filter((val) => val !== id)
    const body = {
      groupIds: checked ? [...filters, id] : [id],
    }
    if (checked) {
      updateCustomer
        .adGroupFilters({
          customerId,
          json: body,
        })
        .then(() => {
          setFilters(newFilters)
          enqueueSnackbar(t('Users_ewaf'), { variant: 'success' })
          setLoading(false)
          setHasChanged(true)
        })
        .catch((err) => {
          err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
          setLoading(false)
        })
    } else {
      deleteCustomer
        .adGroupFilters({
          customerId,
          json: body,
        })
        .then(() => {
          setFilters(newFilters)
          enqueueSnackbar(t('Users_dfgh'), { variant: 'success' })
          setLoading(false)
          setHasChanged(true)
        })
        .catch((err) => {
          err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
          setLoading(false)
        })
    }
    if (
      !loading &&
      !checked &&
      unAvailableFilterGroups.length > 0 &&
      unAvailableFilterGroups.includes(id)
    ) {
      const remainingIds = unAvailableFilterGroups.filter((filterId) => filterId != id)
      setUnAvailableFilterGroups(remainingIds)
    }
  }

  const handleSearch = (searchTerm) => {
    setPage(0)
    const fuse = new Fuse(filteredAdGroups, searchOptions)
    const result = fuse.search(searchTerm)
    setFilteredAdGroups(
      searchTerm.length > 0 ? result : (showActive && activeGroups) || availableAdGroups.data
    )
  }

  const toggleActive = () => setShowActive(!showActive)

  return (
    <>
      <CustomDialog
        isOpen={isOpen}
        onCancel={closeDialog}
        title={t('Users_asdf')}
        onConfirm={handleSetFilterGroup}
        confirmButtonText={t('Drawer_display_save')}
        dialogActions={
          <>
            <Button
              color="primary"
              disabled={loading}
              onClick={closeDialog}
              endIcon={loading && <CircularProgress size={20} />}
            >
              {t('Users_jfhg')}
            </Button>
          </>
        }
      >
        <>
          <Typography variant="body2" color="textSecondary" className={classes.introText}>
            {t('Users_drty')}
          </Typography>
          <Link
            href="https://support.sky.tdc.dk/hc/da/articles/360013828759-S%C3%A5dan-opretter-du-en-gruppe-i-Azure-AD-og-tilf%C3%B8jer-medarbejdere-til-den"
            target="_blank"
          >
            {t('Users_cvbn')}
          </Link>
          {(adGroupFilters.error || availableAdGroups.error) && (
            <PaperFeedbackMessage message="An error occurred" type="error" disablePadding={false} />
          )}
          {adGroupFilters.data && availableAdGroups.data ? (
            <>
              <Alert severity="info" classes={{ root: classes.alertRoot, message: classes.alert }}>
                {t('Users_atwe', { number: availableAdGroups.data.length })}
              </Alert>
              {availableAdGroups.data.length > 9 && (
                <PaperToolbar className={classes.alertRoot}>
                  <Search filter={handleSearch} placeholder={t('Users_jdyt')} />
                  <Button variant="text" color="primary" size="small" onClick={toggleActive}>
                    {showActive ? t('Users_sger') : t('Users_kuytfj')}
                  </Button>
                </PaperToolbar>
              )}

              <List>
                {unAvailableFilterGroups?.length > 0 && (
                  <Alert
                    severity="error"
                    classes={{ root: classes.alertRoot, message: classes.alert }}
                  >
                    {t('Users_atwa', { number: getUnAvailableFilterGroups.length })}
                    {unAvailableFilterGroups?.map((id, i) => (
                      <Fragment key={i}>
                        <ListItem style={{ padding: '8px 0' }}>
                          <ListItemIcon>
                            <GroupIcon />
                          </ListItemIcon>
                          <ListItemText secondary={id} />
                          <Button
                            variant="text"
                            size="small"
                            onClick={() => handleSetFilterGroup(false, id)}
                            className={classes.alertButton}
                          >
                            {t('Wizard_9awef')}
                          </Button>
                        </ListItem>
                        {i !== unAvailableFilterGroups.length - 1 && <Divider />}
                      </Fragment>
                    ))}
                  </Alert>
                )}
                {filteredAdGroups
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((x, index, array) => {
                    return (
                      <Fragment key={x.id}>
                        <ListItem>
                          <ListItemIcon>
                            <GroupIcon />
                          </ListItemIcon>
                          <ListItemText primary={x.displayName} secondary={x.id} />
                          <ListItemSecondaryAction>
                            <Switch
                              edge="end"
                              color="primary"
                              onChange={(_event, checked) => handleSetFilterGroup(checked, x.id)}
                              checked={filters && filters.indexOf(x.id) !== -1}
                              disabled={loading}
                              inputProps={{ 'aria-labelledby': 'add-ad-filter-group' }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        {index !== array.length - 1 && <Divider />}
                      </Fragment>
                    )
                  })}
              </List>
              {availableAdGroups.data.length > 9 && (
                <EnhancedTablePagination
                  arrayLength={filteredAdGroups.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                  entriesPerPageText={t('Users_saerg')}
                />
              )}
            </>
          ) : (
            !adGroupFilters.error && !availableAdGroups.error && <PaperLoader />
          )}
        </>
      </CustomDialog>
    </>
  )
}

export default Dialog
