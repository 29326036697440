import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSubscription } from 'state/subscription/actions'
import { makeStyles } from '@material-ui/core/styles'
import { SwipeableDrawer } from '@material-ui/core'
import { LoadingSpinner, Alert } from '@skytdc/mui/components'
import UserActionRoutes from 'routes/UserActionRoutes'
import { updateUser } from 'services/sweb/user'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import DrawerHeader from './components/DrawerHeader'
import User from './User'
import CreateNoneAdUser from './CreateNoneAdUser'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '90%',
    maxWidth: '660px',
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    overflowX: 'hidden',
    zIndex: 1600,
  },
  innerDrawer: {
    marginBottom: theme.spacing(24),
  },
}))

const Drawer = ({ user, match, history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

  const subscription = useSelector((state) => state.subscription)
  const [open, setOpen] = useState(false)

  const { params, url } = match
  // params.abbId is only set if user is accessed via link og global search - not if clicked in overview
  const isDirectLink = Boolean(params.abbId && !user)
  const certainAbbId = isDirectLink ? params.abbId : user?.abbId
  // if abb, use that, if azure id, use that, if not, drawer closes, yeye TODO
  const certainId = certainAbbId || (user && user.certainId) || ''
  const abbIdsMatch = Boolean(certainAbbId === subscription.abbId)
  const requestedAction = params.requested_action || params.abbId
  const userBaseUrl = `/${params.customer_id}/brugere/${certainId}`
  // eslint-disable-next-line
  const handleOnOpen = () => {}

  const routeIsUser = Boolean(url.endsWith(certainId))

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      history.push({
        pathname: `/${params.customer_id}/brugere`,
        state: null,
      })
    }, 300)
  }

  const changeRoute = (action = '', refetchSubscription = false) => {
    if (routeIsUser && !action) return handleClose()
    if (refetchSubscription) {
      dispatch(fetchSubscription({ customerId: params.customer_id, abbId: user.abbId }))
    }
    return history.push(
      `/${params.customer_id}/brugere/${user.certainId}${action ? `/${action}` : ''}`
    )
  }

  const updateSubscription = () => {
    if (certainAbbId && certainAbbId !== 'opret-bruger' && certainAbbId !== 'simkort') {
      dispatch(fetchSubscription({ customerId: params.customer_id, abbId: certainAbbId }))
    }
  }

  useEffect(() => {
    setOpen(true)
    updateSubscription()
  }, [])

  const userIsResolved = Boolean(
    !certainAbbId || (certainAbbId && abbIdsMatch && subscription.data)
  )

  const {
    name: userName,
    email: userEmail,
    azureId: userAzureId,
    jobTitle: userJobTitle,
    department: userDepartment,
    requiresAzureLink,
  } = user || {}

  const { displayName, id: azureId, mail: email, department, jobTitle } =
    (subscription.data && subscription.data.teams_user_output) || {}
  const createUser = certainAbbId === 'opret-bruger'
  const certainUser = {
    name: userName || displayName || '',
    email: userEmail || email || '',
    azureId: userAzureId || azureId || '',
    jobTitle: userJobTitle || jobTitle || '',
    department: userDepartment || department || '',
    requiresAzureLink,
  }

  const emailNotInSync = user && subscription.data && user.email !== subscription.data?.email

  const updateEmail = () => {
    updateUser
      .updateTeamEmail({
        customerId: params.customer_id,
        json: { subscriptionEmail: subscription.data?.email, azureEmail: user?.email },
      })
      .then(() => {
        updateSubscription()
        enqueueSnackbar(t('Drawer_header_email_in_sync_success'), {
          variant: 'success',
        })
      })
      .catch((err) => {
        err.response
          .json()
          .then((res) =>
            enqueueSnackbar(`Der opstod en fejl ${res.error.text}, prøv igen`, { variant: 'error' })
          )
      })
  }

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={handleOnOpen}
        onClose={handleClose}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        classes={{
          paper: classes.drawer,
        }}
        transitionDuration={375}
      >
        <div className={classes.innerDrawer}>
          {(userIsResolved || createUser) && (
            <DrawerHeader
              name={(createUser && t('Drawer_create_user_title')) || certainUser.name}
              createUser={createUser}
              emailNotInSync={emailNotInSync}
              updateEmail={updateEmail}
              changeRoute={changeRoute}
              routeIsUser={routeIsUser}
              customerId={params.customer_id}
              {...(!createUser && {
                email: certainUser.email,
                azureId: certainUser.azureId,
                requiresAzureLink: certainUser.requiresAzureLink,
              })}
            />
          )}
          {abbIdsMatch && subscription.error && subscription.error.status !== 404 && (
            <Alert message={t('Drawer_user_sub_error')} type="error" withMargin />
          )}
          {userIsResolved ? (
            <>
              {routeIsUser && (
                <User
                  user={certainUser}
                  subscription={(abbIdsMatch && subscription.data) || null}
                  customerId={params.customer_id}
                  currentUrl={url}
                  changeRoute={changeRoute}
                />
              )}
              {requestedAction && (
                <UserActionRoutes
                  userBaseUrl={userBaseUrl}
                  requestedAction={requestedAction}
                  user={certainUser}
                  customerId={params.customer_id}
                  changeRoute={changeRoute}
                  handleClose={handleClose}
                  subscription={(abbIdsMatch && subscription.data) || null}
                />
              )}
            </>
          ) : createUser ? (
            <CreateNoneAdUser
              customerId={params.customer_id}
              changeRoute={changeRoute}
              handleClose={handleClose}
            />
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </SwipeableDrawer>
    </div>
  )
}

export default React.memo(Drawer)
